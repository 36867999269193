import moment from 'moment-timezone'

/**
 * 时间戳 --> 美国时间字符串
 * @param {number} time 时间戳 1648623600000
 * @returns {string} 美国时间字符串 2022-3-30 00:00:00
 */
export function timeFormatUSA (time, fmt='YYYY-MM-DD HH:mm:ss', timezone = 'America/Los_Angeles') {
  return time ? moment.tz(time, timezone).format(fmt) : time
}

/**
 * 美国时间字符串 --> 时间戳
 * @param {string} timeStr 美国时间字符串 2022-3-30 00:00:00
 * @returns {number} 时间戳 1648623600000
 */
export function getUSAtime (timeStr, timezone = 'America/Los_Angeles') {
  return timeStr ? moment.tz(timeStr, timezone).valueOf() : timeStr
}

/**
 * 美国时间字符串 --> 美国当天最后时刻的时间戳
 * @param {string} timeStr 美国时间字符串 2022-3-30 00:00:00
 * @returns {number} 时间戳 1648623599999
 */
export function getUSAendTime (timeStr, timezone = 'America/Los_Angeles') {
  return timeStr ? moment.tz(timeStr, timezone).endOf('day').valueOf() : timeStr
}

// 校验：英文、数字、特殊字符
export function isZH (str) {
  for (var i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i)
    if (!(c >= 0x0001 && c <= 0x007e) && !(0xff60 <= c && c <= 0xff9f)) {
      return true
    }
  }
  return false
}

export function listFormat (list, labelKey, valueKey) {
  return list.map(item => {
    return {
      label: labelKey ? item[labelKey] : item,
      value: valueKey ? item[valueKey] : item
    }
  })
}

export function replaceImgUrl (url) {
  return url.replace(/^https:\/\/((vesync-cloud-recall-cn.s3.cn-northwest-1.amazonaws.com.cn)|(files-recall.vesync.cn))/, PAGECONF.imgApi)
}
